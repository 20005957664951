<template>
  <div className="animated">
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>User List <MembersExport
          v-if="this.viewUserRole === 'member'"
          :params="options.requestAdapter()"
        /> <span class="float-right"><router-link to="/user/form">New User</router-link></span>
      </b-card-header>
      <b-card-body>

        <b-row>

          <b-col>
            <b-form-select
              :options="[{ value: '1', text: 'Active' }, { value: '0', text: 'Inactive' }]"
              v-model="queryFilter.active"
            ></b-form-select>
          </b-col>

          <b-col v-if="['coach','coach_jr'].includes(this.viewUserRole)" cols="12" md="10">
            <b-form-checkbox-group
              v-model="queryFilter.sports"
              :options="sports"
              class="mb-3"
              value-field="@id"
              text-field="label"
            ></b-form-checkbox-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <v-server-table 
      class="bg-light p-2 vue-table-table w-100"
      name="dataTableUserList"
      :columns="columns"
      :url="url"
      :options="options"
      :useVuex="true"
      :theme="theme"
      id="dataTable"
      ref="table-users"
      :preserveState="preserveState"
      @select="selected = $event"
    >
      <div slot="created" slot-scope="props">
        {{ $moment(props.row.created).format('MM/DD/YY h:mma') }}
      </div>
      <div
        slot="email"
        slot-scope="props"
      >
        <a
          :href="'mailto:' + props.row.email"
          :title="'Email ' + props.row.fullName + ' <' + props.row.email + '>'"
        >{{ props.row.email }}</a>
      </div>
      <div slot="active" slot-scope="props">
        {{ props.row.active }}
      </div>
      <div slot="registeredEvents" slot-scope="props">
        {{ props.row.registeredEvents.length || '-' }}
      </div>
      <div slot="actions" slot-scope="props" class="text-center">
        <b-button-group>
          <b-btn size="sm" variant="info" @click="$refs.userViewModal.open(props.row.id)">View</b-btn>
          <b-btn size="sm" variant="warning" :to="{ name: 'userForm', params: { uid: props.row.id }}">Edit</b-btn>
          <b-btn v-if="userRole === 'director'" size="sm" variant="light" @click="doLoginAs(props.row.username)">Become</b-btn>
        </b-button-group>
      </div>
    </v-server-table>
    <b-dropdown v-if="isDirector" id="dropdown-selected" text="With Selected..." class="m-md-2">
      <b-dropdown-item v-if="isDirector" @click="removeMember">Remove</b-dropdown-item>
    </b-dropdown>
    <!-- view -->
    <UserViewModal ref="userViewModal" />
  </div>
</template>

<script>
import Vue from 'vue'
import {HTTP} from '@/services/http-common'
// import { ClientTable, Event } from 'vue-tables-2-premium'
import { ServerTable, Event } from 'vue-tables-2-premium'
import { constants } from '@/shared/constants'
import UserViewModal from '../../components/UserViewModal'
import { mapGetters, mapActions } from 'vuex'
import MembersExport from '@/components/MembersExport'
// use ServerTable
Vue.use(ServerTable)

export default {
  name: 'UserList',
  components: {
    ServerTable,
    Event,
    UserViewModal,
    MembersExport
  },
  props: {
    userRole: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      viewUserRole: null,
      // NEW OPTIONS
      options: {
        debounce: 750,
        perPage: 30,
        perPageValues: [30,60,90],
        selectable:{
          mode: 'multiple',
          selectAllMode: 'page',
          programmatic: false
        },
        requestFunction (data) {
          return HTTP.get(this.url, {
              params: data
          }).catch(function (e) {
              this.dispatch('error', e)
          }.bind(this))
        },
        requestAdapter (data) {
          data = data || {}
          let query = {
            active: data.active ? data.active : '1',
            sort: data.orderBy ? data.orderBy : 'fullName',
            direction: data.ascending ? 'asc' : 'desc',
            page: data.page ? data.page : '1',
            itemsPerPage: data.limit ? data.limit : constants.LIST_DEFAULT_ITEMS_PER_PAGE
          }
          if (data.query && data.query !== '') {
            query.fullName = data.query.trim()
          }
          if (data.orderBy) {
            let param = 'order[' + data.orderBy + ']'
            query[param] = data.ascending ? 'asc' : 'desc'
          } else {
            query['order[fullName]'] = 'desc'
          }
          return query
        },
        responseAdapter ({data}) {
          return {
            data: data['hydra:member'],
            count: data['hydra:totalItems']
          }
        },
        headings: {
          id: 'UID',
          created: 'Date',
          fullName: 'Name',
          email: 'Email',
          'source.name': 'Source',
          roles: 'Role(s)',
          registeredEvents: 'Registrations',
          actions: 'Actions'
        },
        sortable: ['id', 'created', 'fullName', 'email', 'roles'/* , 'source.name', 'registeredEvents' */],
        filterable: ['id', 'created', 'fullName', 'email', 'source.name', 'roles', 'registeredEvents'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      // settings
      selected: [],
      preserveState: true,
      useVuex: true,
      theme: 'bootstrap4',
      template: 'default',
      queryFilter: {
        active: 1,
        sports: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'userList',
      'business'
    ]),
    url () {
      const params = { roles: 'role_' + this.viewUserRole }
      for (const k in this.queryFilter) {
        if (this.queryFilter[k] !== null) params[k] = this.queryFilter[k]
      }
      if (this.business !== null && this.business.id) {
        params.businesses = this.business.id
      }
      const query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      return '/users?' + query
    },
    sports () {
      let s = this.$store.getters.sports
      return s.filter((e) => {
        return (!constants.LOOKUP_EXCLUDE.includes(e.label))
      })
    },
    columns () {
      let cols = ['id', 'created', 'fullName', 'phone', 'email']
      if (this.currentUserRole === 'ROLE_ADMIN') {
        cols.push('roles')
      }
      if (['member','coach','coachJr'].includes(this.viewUserRole)) {
        cols.push('source.name')
      }
      if (this.viewUserRole === 'member') {
        cols.push('registeredEvents')
      }
      // cols.push('active')
      cols.push('actions')
      return cols
    }
  },
  filters: {
    formatRole: function (value) {
      if (!value) return ''
      value = value.substring(4).toLowerCase()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
    userRole (/* val */) {
      
    },
    queryFilter: {
      handler (/* val */) {
        // for (const k in val) {
        //   if (val[k] !== null && !val[k].length) {
        //     val[k] = null
        //   }
        // }
      },
      deep: true
    },
    '$route.path' () {
      this.viewUserRole = (this.userRole === 'coachJr') ? 'coach_jr' : this.userRole // @FIXME
    }
  },
  created () {
    if (!this.userRole) {
      // @TODO assign by this.currentUserRole
      this.viewUserRole = "ROLE_MEMBER"
    } else {
      this.viewUserRole = (this.userRole === 'coachJr') ? 'coach_jr' : this.userRole // @FIXME
    }
  },
  async mounted () {
    if (this.business && this.business.id) {
      await HTTP.get('https://api.nzonesports.com/en/fixmemassoc/' + this.business.id)
    }
  },
  destroyed() {
    this.$store.dispatch('resetUsers')
  },
  methods: {
    ...mapActions([
      'getUsers',
      'putUser',
      'loginAs'
    ]),
    doLoginAs (username) {
      this.loginAs(username).then(() => {
        this.$router.push('/')
      })
    },
    // doGetUsers () {
    //   let roles = this.viewUserRole
    //   if (roles === 'coachJr') {
    //     roles = 'coach_jr' // @FIXME
    //   }
    //   let params = { pagination: false, roles: this.viewUserRole }
    //   if (this.currentBusiness !== null) {
    //     params.businesses = this.currentBusiness.id
    //   }
    //   this.getUsers({...params, ...this.queryFilter})
    // },
    removeMember () {
      if (this.selected.length) {
        this.$bvModal.msgBoxConfirm('Are you sure you want to remove the selected members?', {
          title: 'Remove Members',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(async confirm => {
          if (confirm) {

            // remove members
            const rm = this.selected.map(user => {
              return this.unassociateUserLocation(user)
            })
            await Promise.all(rm)

            // refresh
            this.$refs['table-users'].refresh()
            // this.$bvModal.msgBoxOk('Members removed successfully', {
            //   title: 'Remove Members',
            //   size: 'sm',
            //   buttonSize: 'sm',
            //   okVariant: 'success',
            //   okTitle: 'OK',
            //   footerClass: 'p-2',
            //   hideHeaderClose: false,
            //   centered: true
            // })
            this.selected = []
          }
        })
        .catch(err => {
          console.error(err)
        })
      }
    },
    async unassociateUserLocation (user) {
      await this.$recaptchaLoaded()

      // unassociate user w/ current location
      let businesses = user.businesses.filter(business => {
        return business['@id'] !== this.currentBusiness['@id']
      }).map(business => {
        return business['@id']
      })

      let captcha = await this.$recaptcha('admin_member_unassociate')
      if (captcha) {
        let obj = { 
          uid: user.id, 
          data: { captcha, businesses }
        }
        return await this.putUser(obj)
      }
    },
  },
};
</script>

<style lang="scss">
#dataTable {
  width: 95%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .vue-title {
    text-align: center ;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
